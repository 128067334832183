import * as React from "react";

function Phone(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M2.828.765a2.617 2.617 0 013.915.245l2.692 3.459c.494.634.668 1.461.473 2.241l-.82 3.285c-.087.346.014.712.266.965l3.686 3.685c.253.253.62.354.966.267l3.283-.82a2.617 2.617 0 012.241.472l3.459 2.692a2.617 2.617 0 01.244 3.916l-1.55 1.551c-1.11 1.11-2.77 1.598-4.316 1.053a27.949 27.949 0 01-10.515-6.63A27.953 27.953 0 01.222 6.632C-.32 5.087.168 3.426 1.278 2.316L2.828.766z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Phone;
