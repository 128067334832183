import * as React from "react";

function Mail(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="18"
      fill="none"
      viewBox="0 0 24 18"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M3 0A3 3 0 00.075 2.333L12 9.62l11.925-7.289A3 3 0 0021 0H3zM0 14.701V4.046l8.704 5.318L0 14.701zm10.142-4.456l-9.855 6.04A3 3 0 003 18h18a3 3 0 002.712-1.716l-9.855-6.04L12 11.378l-1.858-1.136v.002zM24 14.702l-8.704-5.336v-.002L24 4.045v10.656z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Mail;
