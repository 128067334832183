import React, { useState } from "react";
import SectionTitle from "../ui/SectionTitle";
import SectionSubtitle from "../ui/SectionSubtitle";
import SectionParagraph from "../ui/SectionParagraph";
import FormInput from "../ui/FormInput";
import FormTextarea from "../ui/FormTextarea";
import config from "../../../config.json";
import Phone from "../ui/icons/Phone";
import Mail from "../ui/icons/Mail";
import Pin from "../ui/icons/Pin";

const Contact = () => {
  const [isSend, setIsSend] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    let myForm = document.getElementById("contact-form");
    let formData = new FormData(myForm);
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => setIsSend(true))
      .catch((error) => alert(error));
  };

  return (
    <section id="kontakt">
      <div className="container mx-auto px-5 py-8 md:py-16">
        <div className="flex flex-row justify-between flex-wrap lg:flex-nowrap gap-8 mb-16">
          <div className="lg:w-1/2">
            <SectionSubtitle>Napisz do nas</SectionSubtitle>
            <SectionTitle>Kontakt</SectionTitle>
            <SectionParagraph className="mb-5">
              Nasi pracownicy z chęcią odpowiedzą na szczegółowe pytanie
              dotyczące oferty lub wstępnie wycenią wartość sprzedawanego złomu.
            </SectionParagraph>
            <SectionParagraph>
              Zapraszamy do kontaktu telefonicznego, mailowego, przez formularz
              kontaktowy lub do odwiedzenia naszego punktu.
            </SectionParagraph>
          </div>
          <div className="w-full lg:w-1/2">
            <form
              id="contact-form"
              className="mt-10"
              name="Contact Form"
              method="POST"
              netlify-honeypot="bot-field"
              data-netlify="true"
              onSubmit={(e) => handleSubmit(e)}
            >
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="Contact Form" />
              <FormInput
                name="name"
                placeholder="Imię i nazwisko"
                required={true}
              >
                Imię i nazwisko
              </FormInput>
              <FormInput
                name="email"
                placeholder="Adres e-mail"
                required={true}
              >
                E-mail
              </FormInput>
              <FormInput name="phone" placeholder="Telefon" required={true}>
                Telefon
              </FormInput>
              <FormTextarea
                name="message"
                placeholder="Wiadomość"
                required={true}
              >
                Telefon
              </FormTextarea>
              <button
                type="submit"
                className="bg-primary hover:bg-white hover:text-primary text-white py-3 px-10
                mt-5 border-primary border-2 transition duration-500 uppercase focus:outline-none"
              >
                Wyślij
              </button>
            </form>
            {isSend && (
              <div className="bg-green-500 mt-8 p-6">
                <p className="text-xl text-white">Wiadomość została wysłana.</p>
              </div>
            )}
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 mb-16">
          <div className="border md:rounded-l-lg px-10 py-6">
            <div className="flex items-center mb-5">
              <div className="bg-secondary rounded-full flex items-center justify-center p-1 w-14 h-14 mr-4">
                <Phone className="fill-white" />
              </div>
              <h3 className="text-secondary uppercase text-lg tracking-widest">
                Telefon
              </h3>
            </div>
            <p className="text-xl text-gray-700">{config.phone}</p>
          </div>
          <div className="border-x md:border-x-0 md:border-y px-10 py-6">
            <div className="flex items-center mb-5">
              <div className="bg-secondary rounded-full flex items-center justify-center p-1 w-14 h-14 mr-4">
                <Mail className="fill-white" />
              </div>
              <h3 className="text-secondary uppercase text-lg tracking-widest">
                Email
              </h3>
            </div>
            <p className="text-xl text-gray-700">{config.email}</p>
          </div>
          <div className="border md:rounded-r-lg px-10 py-6">
            <div className="flex items-center mb-5">
              <div className="bg-secondary rounded-full flex items-center justify-center p-1 w-14 h-14 mr-4">
                <Pin className="fill-white" />
              </div>
              <h3 className="text-secondary uppercase text-lg tracking-widest">
                Adres
              </h3>
            </div>
            <p className="text-xl text-gray-700">{config.address}</p>
          </div>
        </div>

        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d148785.42105176538!2d18.549945532896444!3d54.361006259536346!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46fd731c14d4fa6f%3A0x9bb9fbf163b7be8d!2zR2RhxYRzaw!5e0!3m2!1spl!2spl!4v1648032020055!5m2!1spl!2spl"
          width="600"
          height="450"
          title="Map"
          style={{ width: "100%", border: 0 }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
    </section>
  );
};

export default Contact;
