import React from "react";

const FormTextarea = ({ name, placeholder, required = false }) => {
  return (
    <textarea
      id={name}
      type="text"
      rows="4"
      name={name}
      placeholder={placeholder}
      className="block w-full p-3 my-3
                    text-gray-800 appearance-none
                    border-2 rounded-lg border-gray-200
                    focus:text-gray-500 focus:outline-none focus:border-gray-200"
      required={required}
    ></textarea>
  );
};

export default FormTextarea;
