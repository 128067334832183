import * as React from "react";

function Pin(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="24"
      fill="none"
      viewBox="0 0 23 24"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M12.513 23.956c5.935-.513 10.486-5.37 10.487-11.194 0-5.075-3.48-9.52-8.491-10.85.133-.452.04-.94-.25-1.316A1.545 1.545 0 0013.034 0H9.967c-.481 0-.935.22-1.225.596-.29.376-.382.864-.25 1.316C2.743 3.436-.865 9 .18 14.732c1.045 5.733 6.398 9.736 12.333 9.224zm4.949-17.044l.003-.002-.003.001zm0 0l-7.586 4.236-4.34 7.42 7.59-4.239 4.336-7.417z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Pin;
