import * as React from "react";
import Layout from "../components/global/Layout";
import Contact from "../components/sections/Contact";

const ContactPage = () => {
  return (
    <Layout
      titleTemplate="%s"
      pageTitle="Skontaktuj się z nami"
      pageDescription="Skontaktuj się z nami drogą elektroniczną lub telefoniczną"
    >
      <Contact />
    </Layout>
  );
};

export default ContactPage;
